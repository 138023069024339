
import { VenueTransactions } from '@/interfaces/models/VenueTransactions';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import moment, { Moment } from 'moment';
import { VenueTransactionStatus } from '@/enums/VenueTransactionStatus';
import { Permission } from '@/enums/Permission';

interface MandateVenue {
  _id: string;
  venue: string;
  error?: string;
}

interface MandateMapEntry {
  hasPayableTransactions?: boolean;
  venues: MandateVenue[];
}

@Component
export default class MandateList extends Vue {
  @Prop({ type: Array, required: true }) private venues!: Venue[];
  @Prop({ type: Function, required: false }) private lastTransactionsPayable!: (venues: string[]) => Promise<boolean>;

  private sepaVenuesMap: Map<string, MandateMapEntry> = new Map<string, MandateMapEntry>();

  public mounted(): void {
    this.$startLoading('loadMandates');
    this.loadSepaVenuesMap();
    this.$stopLoading('loadMandates');

    this.checkLastTransactionsPayable();
  }

  private sepaMandateUrl(mandate: string): string {
    return `${process.env.VUE_APP_GOCARDLESS_URL}/mandates/${mandate}`;
  }

  private loadSepaVenuesMap(): void {
    for (const venue of this.venues) {
      if (!this.hasSepaMandate(venue)) {
        continue;
      }

      const entry: MandateVenue = {
        _id: venue._id,
        venue: `${venue.name}/${venue.readableId}`,
        error: venue.invoiceReceiver.sepa!.goCardlessError!,
      };
      if (!this.sepaVenuesMap.has(venue.invoiceReceiver.sepa!.sepaMandateRef!)) {
        this.sepaVenuesMap.set(venue.invoiceReceiver.sepa!.sepaMandateRef!, { venues: [entry] });
      } else {
        this.sepaVenuesMap.get(venue.invoiceReceiver.sepa!.sepaMandateRef!)!.venues.push(entry);
      }
    }
  }

  private async checkLastTransactionsPayable(): Promise<void> {
    this.$startLoading('payableMandates');

    if (this.$can(Permission.VENUE_SEPA_PAY) && this.lastTransactionsPayable) {
      for (const entry of this.sepaVenuesMap.values()) {
        const venueIds: string[] = entry.venues.map((venue: MandateVenue) => venue._id);
        entry.hasPayableTransactions = await this.lastTransactionsPayable(venueIds);
      }
    }

    this.$stopLoading('payableMandates');
  }

  private hasSepaMandate(venue: Venue): boolean {
    return (
      ((venue.invoiceReceiver && venue.invoiceReceiver.sepa && venue.invoiceReceiver.sepa.sepaMandateRef) || null) !==
      null
    );
  }

  @Emit('cancelMandate')
  private cancel(data: MandateVenue[]): string[] {
    return data.map((item: MandateVenue) => item._id);
  }

  @Emit('payLastTransactions')
  private payLastTransactions(data: MandateVenue[]): string[] {
    return data.map((item: MandateVenue) => item._id);
  }
}
