
import { Component, Prop, Vue } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '../../enums/InputType';
import { Venue } from '../../interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { venueFilter } from '@/util/helper';
@Component({
  components: { VFormBuilder },
})
export default class MandateForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  get items() {
    return [
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'sepa.form.venues',
        multiple: true,
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        filter: venueFilter,
        rules: 'required',
      },
      {
        name: 'payLastInvoices',
        type: InputType.Checkbox,
        label: 'sepa.form.payLastTransactionsAfterCreate',
      },
    ];
  }
}
