
import { Component } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import { BindingHelpers, namespace } from 'vuex-class/lib/bindings';
import { Venue } from '@/interfaces/models/Venue';
import MandateList from '@/components/sepa/MandateList.vue';
import MandateForm from '@/components/sepa/MandateForm.vue';
import VConfirm from '@/components/shared/VConfirm.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import PaymentApiService from '@/api/http/PaymentApiService';
import { AxiosError, AxiosResponse } from 'axios';
import { VenueSepa } from '@/interfaces/models/VenueSepa';
import { VenueTransactions } from '@/interfaces/models/VenueTransactions';

const app: BindingHelpers = namespace('app');

@Component({
  components: { VConfirm, MandateForm, MandateList, VWrapper },
})
export default class SepaSettings extends mixins(StackedForm, Notification) {
  @app.State('venues') public venues!: Venue[];
  @app.Action('fetchVenues') public getVenues!: any;

  public $refs!: {
    mandateForm: InstanceType<typeof MandateForm> & {
      form: any;
      validate: () => Promise<boolean | boolean[]>;
      getData: () => { venues: string[] };
    };
    confirmCreate: InstanceType<typeof VConfirm> & {
      setText: (str: string) => void;
      open: (result: {} | PromiseLike<{}> | undefined) => boolean;
    };
    confirmDelete: InstanceType<typeof VConfirm> & {
      setText: (str: string) => void;
      open: (result: {} | PromiseLike<{}> | undefined) => boolean;
    };
    confirmPayLast: InstanceType<typeof VConfirm> & {
      setText: (str: string) => void;
      open: (result: {} | PromiseLike<{}> | undefined) => boolean;
    };
  };

  public async created() {
    await this.loadVenues();
  }

  public async mounted(): Promise<void> {
    this.$refs.confirmCreate.setText(`${this.$t('sepa.notification.createWarning')}`);
    this.$refs.confirmDelete.setText(`${this.$t('sepa.notification.cancelWarning')}`);
    this.$refs.confirmPayLast.setText(`${this.$t('sepa.notification.payLastTransactionsWarning')}`);
  }

  public async lastTransactionsPayable(venues: string[]): Promise<boolean> {
    const api: PaymentApiService = new PaymentApiService();
    return api
      .lastTransactionsPayable({ venues })
      .then((result: AxiosResponse<boolean>) => !!result.data)
      .catch();
  }

  public createMandate(): void {
    this.$refs.confirmCreate.open(async (result: {} | PromiseLike<{}> | undefined) => {
      if (await result) {
        this.$refs.mandateForm.validate().then((res: boolean | boolean[]) => {
          if (this.isValid(res)) {
            const api: PaymentApiService = new PaymentApiService();
            api
              .createSepaLink(this.$refs.mandateForm.getData())
              .then((result: AxiosResponse<VenueSepa>) => {
                window.open(result.data.goCardlessSepaFlowUrl, '_blank');
                this.loadVenues();
              })
              .catch(() => {
                this.notifyError('sepa.notification.createError');
              });
          }
        });
      }
    });
  }

  public cancelMandate(venues: string[]): void {
    this.$refs.confirmDelete.open(async (result: {} | PromiseLike<{}> | undefined) => {
      if ((await result) && venues.length > 0) {
        const api: PaymentApiService = new PaymentApiService();
        api
          .cancelSepaMandate({ venues })
          .then(async () => {
            this.loadVenues();
          })
          .catch(() => {
            this.notifyError('sepa.notification.cancelError');
          });
      }
    });
  }

  public payLastTransactions(venues: string[]) {
    this.$refs.confirmPayLast.open(async (result: {} | PromiseLike<{}> | undefined) => {
      if ((await result) && venues.length > 0) {
        try {
          const api: PaymentApiService = new PaymentApiService();
          const response: AxiosResponse<string[]> = await api.sepaPayLastTransactions({ venues });
          this.notifySuccess(`${response.data.length} ${this.$t('sepa.notification.payLastTransactionsSuccess')}`);
        } catch (err) {
          this.notifyError('notification.500');
        }

        this.loadVenues();
      }
    });
  }

  private async loadVenues(): Promise<void> {
    this.$startLoading('venues');
    await this.getVenues();
    this.$stopLoading('venues');
  }
}
